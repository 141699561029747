import { render, staticRenderFns } from "./LinkList.vue?vue&type=template&id=1938774e&scoped=true&functional=true"
var script = {}
import style0 from "./LinkList.vue?vue&type=style&index=0&id=1938774e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1938774e",
  null
  
)

export default component.exports